/* Utility: Audio/Podcast block
-------------------------------------------------- */

.podcast-block .jp-audioBase {
    min-width: 300px;
    background: -webkit-radial-gradient(ellipse at center, #feffff 0%,#d2ebf9 100%);
    background: -o-radial-gradient(ellipse at center, #feffff 0%,#d2ebf9 100%);
    background: -ms-radial-gradient(ellipse at center, #feffff 0%,#d2ebf9 100%);
    background: -webkit-radial-gradient(ellipse at center, #feffff 0%, #d2ebf9 100%);
    background: -o-radial-gradient(ellipse at center, #feffff 0%, #d2ebf9 100%);
    background: radial-gradient(ellipse at center, #feffff 0%,#d2ebf9 100%);
    box-shadow: 0 0 2px 2px #999;
    margin-bottom: 20px;
}

.podcast-block .jp-audio {
    margin-bottom: 20px;
}

.podcast-block .jp-free-media {
    display: none;
}

.podcast-block .jp-controls li,
.podcast-block .jp-toggles li,
.podcast-block .jp-playlist li {
    display: inline;
    font-family: Arial,sans-serif;
}

.podcast-block .jp-controls,
.podcast-block .jp-playlist ul {
    margin-left: auto;
    padding-left: 0;
}

.podcast-block .jp-volume-bar,
.podcast-block .jp-volume-bar-value {
    display: inline-block;
    width: auto;
    margin-bottom: 0;
    vertical-align: text-bottom;
    cursor: pointer;
}

.podcast-block .jp-progress,
.podcast-block .jp-seek-bar,
.podcast-block .jp-play-bar {
    width: auto;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 0;
}

.podcast-block .jp-progress .progess,
.podcast-block .jp-progress .progress-bar {
    -webkit-transition: width 0s;
    -o-transition: width 0s;
    -ms-transition: width 0s;
    transition: width 0s;
}

.podcast-block #time {
    height: 20px;
}

.podcast-block .jp-current-time {
    float: left;
}

.podcast-block .jp-duration {
    float: right;
}

.podcast-block .jp-playlist li div {
    margin: 5px;
}

.podcast-block .jp-playlist-item-remove {
    float: right;
}

.podcast-block .jp-controls {
    width: 40px;
    float: left;
}

.podcast-block .jp-seek-bar.progress {
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 0;
}

.podcast-block .jp-playlist.trunk-playlist ul li div {
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.podcast-block li.jp-playlist-current div {
    background-color: rgba(0,0,0,.1);
}

.podcast-block .jp-playlist.trunk-playlist ul li:hover div {
    background-color:rgba(0,0,0,.1);
}

.podcast-block .jp-current-time.badge,
.podcast-block .jp-duration.badge {
    background-color: rgba(0,0,0,.4);
    border-radius: 0;
}

.podcast-block .jp-controls a {
    color: #000 !important;
}

.podcast-block .jp-gui.jp-interface,
.podcast-block .card-image-container,
.podcast-block .card-body {
    margin-bottom: 20px;
}

.podcast-block .subscribe {
    text-align: left;
    padding-bottom: 10px;
}

.podcast-block .itunes,
.podcast-block .android,
.podcast-block .rss {
    display: inline-block;
    padding: 7px;
    border: 1px solid rgba(0,0,0,.1);
}

.podcast-block .itunes:hover,
.podcast-block .android:hover,
.podcast-block .rss:hover {
    background-color: rgba(0,0,0,.1);
}

.podcast-block .subscribe a:hover,
.podcast-block .jp-playlist-item:hover {
    text-decoration: none;
}

.podcast-block .itunes {
    margin-right: 10px;
} 

.podcast-block .rss {
    margin-left: 10px;
}   

@media only screen and (max-width : 375px) {
    .podcast-block .subscribe {
        text-align: center;
    }

    .podcast-block .itunes {
        margin: 0 auto 10px auto;
    }

    .podcast-block .android {
        margin: 0 auto;
    }
}